import React, { useContext } from 'react';
import { ThemeContext,LanguageContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';

import { LegalsViewWrapper } from 'ToolboxComponents/webapp/pages/legals-view/legals-view.styles';

const LegalsView = ( {infos} ) => {
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const { url, owner, publisher } = infos;
  return (
    <>
      {language === 'fr' ? 
        <LegalsViewWrapper theme={theme}>
        <h2>Informations légales</h2>
  
        <br />
  
        <h3>1. Présentation du site.</h3>
  
        <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site <a href="/">{url}</a> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
        <strong>Propriétaire</strong> : {owner}<br />
        <strong>Créateur</strong>  : Agitateurs de Destinations Numériques, filiale de StudioJuillet, 2 avenue du Commandant Lisiack, 17440 Aytré<br />
        <strong>Responsable publication</strong> : {publisher}<br />
        <strong>Webmaster</strong> : Pierre Eloy – <a href="mailto:direction@agitateurs.com">direction@agitateurs.com</a><br />
        <strong>Hébergeur</strong> : SCALEWAY SAS BP 438 75366 PARIS CEDEX 08<br />
        <p/>
  
        <h3>2. Conditions générales d’utilisation du site et des services proposés.</h3>
  
        <p>L’utilisation du site <a href="/">{url}</a> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site <a href="/">{url}</a> sont donc invités à les consulter de manière régulière.</p>
        <p>Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par {owner}, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.</p>
        <p>Le site <a href="/">{url}</a> est mis à jour régulièrement par {owner}. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>
  
        <h3>3. Description des services fournis.</h3>
  
        <p>Le site <a href="/">{url}</a> a pour objet de fournir une information concernant l’ensemble des activités touristiques sur la métropole stéphanoise.</p>
        <p>{owner} s’efforce de fournir sur le site <a href="/">{url}</a> des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>
        <p>Tous les informations indiquées sur le site <a href="/">{url}</a> sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site <a href="/">{url}</a> ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>
  
        <h3>4. Limitations contractuelles sur les données techniques.</h3>
  
        <p>Le site utilise la technologie React JS.</p>
        <p>Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour</p>
  
        <h3>5. Propriété intellectuelle et contrefaçons.</h3>
  
        <p>{owner} est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.</p>
        <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de {owner}.</p>
        <p>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>
  
        <h3>6. Limitations de responsabilité.</h3>
  
        <p>{owner} ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site <a href="/">{url}</a>, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.</p>
        <p>{owner} ne pourra également être tenu responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site <a href="/">{url}</a>.</p>
        <p>Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. {owner} se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, {owner} se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…).</p>
  
        <h3>7. Gestion des données personnelles.</h3>
  
        <p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
        <p>A l'occasion de l'utilisation du site <a href="/">{url}</a>, peuvent êtres recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au site <a href="/">{url}</a>, le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur.</p>
        <p> En tout état de cause {owner} ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site <a href="/">{url}</a>. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site <a href="/">{url}</a> l’obligation ou non de fournir ces informations.</p>
        <p>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.</p>
        <p>Aucune information personnelle de l'utilisateur du site <a href="/">{url}</a> n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. </p>
        <p>Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>
  
        <h3>8. Liens hypertextes et cookies.</h3>
  
        <p>Le site <a href="/">{url}</a> contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de {owner}. Cependant, {owner} n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</p>
        <p>La navigation sur le site <a href="/">{url}</a> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.</p>
        <p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies :</p>
        <br />
        <p className="underlined">Sous Internet Explorer :</p>
        <ol>
          <li>Onglet outil (pictogramme en forme de rouage en haut a droite)</li>
          <li>Options internet</li>
          <li>Cliquez sur Confidentialité</li>
          <li>Choisissez Bloquer tous les cookies</li>
          <li>Validez sur Ok</li>
        </ol>
        <p className="underlined">Sous Firefox :</p>
        <ol>
          <li>En haut de la fenêtre du navigateur, cliquez sur le bouton Firefox</li>
          <li>Onglet Options</li>
          <li>Cliquez sur l'onglet Vie privée</li>
          <li>Paramétrez les Règles de conservation</li>
          <li>Utilisez les paramètres personnalisés pour l'historique</li>
          <li>Décochez pour désactiver les cookies</li>
        </ol>
        <p className="underlined">Sous Safari :</p>
        <ol>
          <li>Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par un rouage)</li>
          <li>Sélectionnez Paramètres</li>
          <li>Cliquez sur Afficher les paramètres avancés</li>
          <li>Dans la section "Confidentialité", cliquez sur Paramètres de contenu</li>
          <li>Dans la section "Cookies", vous pouvez bloquer les cookies</li>
        </ol>
        <p className="underlined">Sous Chrome :</p>
        <ol>
          <li>Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par trois lignes horizontales)</li>
          <li>Sélectionnez Paramètres</li>
          <li>Cliquez sur Afficher les paramètres avancés</li>
          <li>Dans la section "Confidentialité", cliquez sur préférences</li>
          <li>Dans l'onglet "Confidentialité", vous pouvez bloquer les cookies</li>
        </ol>
  
        <h3>9. Droit applicable et attribution de juridiction.</h3>
  
        {/* <p>Tout litige en relation avec l’utilisation du site <a href="/">{url}</a> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de {city}.</p> */}
        <p>Tout litige en relation avec l’utilisation du site <a href="/">{url}</a> est soumis au droit français. Les tribunaux Français seront seuls compétents.</p>
  
        <h3>10. Les principales lois concernées.</h3>
  
        <p>Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.</p>
        <p>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.</p>
        <p>Règlement général sur la protection des données no 2016/679 (RGPD) de l'Union européenne du 14 avril 2016.</p>
  
        <h3>11. Lexique.</h3>
  
        <p>Utilisateur : Internaute se connectant, utilisant le site susnommé.</p>
        <p>Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</p>
      </LegalsViewWrapper>:
      <LegalsViewWrapper theme={theme}>
        <h2>Legal information</h2>

        <br />

        <h3>1. Presentation of the website</h3>

        <p>Pursuant to Article 6 of Act no. 2004-575 of 21 June 2004 on confidence in the digital economy, Users of the  <a href="/">{url}</a> website are informed of the identity of the various parties involved in its creation and monitoring:</p>
        <strong>Owner</strong> : {owner}<br />
        <strong>Designer</strong>  : {<a target="_blank" href="https://www.agitateurs.com">Agitateurs de Destinations Numériques</a>}<br />
        <strong>Publication manager</strong> : {publisher}<br />
        <strong>Webmaster</strong> : Pierre Eloy – <a href="mailto:direction@agitateurs.com">direction@agitateurs.com</a><br />
        <strong>Host</strong> : 2ISR - 16 Boulevard du Général Faidherbe, 49300 Cholet<br />
        <p/>

        <h3>2. General conditions of use of the website and the services offered.</h3>

        <p>Use of the <a href="/">{url}</a> website implies full acceptance of the general conditions of use described below. These conditions of use may be modified or expanded at any time and Users of the  <a href="/">{url}</a> website are therefore asked to review them regularly.</p>
        <p>This website is normally accessible to Users at all times. Any interruption due to technical maintenance may however be decided by {owner}, which will then endeavour to communicate the dates and times of the intervention to Users in advance.</p>
        <p>The <a href="/">{url}</a> website is regularly updated by {owner}. Similarly, the legal notices may be modified at any time, but they are nevertheless binding on the Users, who are asked to consult them as often as possible in order to take note of any such changes.</p>

        <h3>3. Description of services provided</h3>

        <p>The purpose <a href="/">{url}</a> website is to provide information on all tourist activities in the Greater Saint-Etienne area.</p>
        <p>{owner} strives to provide the most accurate information possible on the <a href="/">{url}</a> website. However, it cannot be held liable for any omissions, inaccuracies or shortcomings in the updating, whether caused by itself or by third-party partners who provide it with this information.</p>
        <p>All information given on the <a href="/">{url}</a> website is indicative and is liable to change. Moreover, the information featured on the <a href="/">{url}</a> website is not exhaustive. It is given subject to modifications having been made since it was put online.</p>

        <h3>4. Contractual limitations on technical data</h3>

        <p>The website uses React JS technology.</p>
        <p>The website may not be held liable for any material damages linked to its use. Furthermore, the User of the website undertakes to access the website using recent hardware, virus-free and with an updated latest generation browser.</p>

        <h3>5. Intellectual property and counterfeits</h3>

        <p>{owner} owns intellectual property rights or holds the rights of use on all the elements accessible on the website, in particular the texts, images, graphics, logos, icons, sounds, software.</p>
        <p>Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the website, whatever the means or the process used, is forbidden, except with the prior written authorization of {owner}</p>
        <p>Any unauthorized use of the website or of any of the elements it contains will be considered as constituting an infringement and will be prosecuted pursuant to the provisions of articles L.335-2 et seq. of the French Intellectual Property Code.</p>

        <h3>6. Limitations of Liability</h3>

        <p>{owner} may not be held liable for any direct or indirect damage caused by the User’s hardware when accessing the <a href="/">{url}</a>  website and resulting from the use of hardware not satisfying the indications of point 4 or from the appearance of a bug or any other incompatibility. </p>
        <p>{owner} may not be held liable for indirect damage (such as example the loss of a contract or loss of opportunity) consecutive to the use of the <a href="/">{url}</a>  website.</p>
        <p> Interactive spaces (possibility of asking questions in the contact area) are available to Users. {owner} reserves the right to delete, without prior warning, any content left in this area which would constitute an infringement of French law, in particular regarding data protection. Where applicable, {owner} also reserves the right to hold the User civilly and/or criminally liable, especially in the case of racist, insulting, defamatory or pornographic messages regardless of the form (text, photo, etc.).</p>

        <h3>7. Personal data management</h3>

        <p>In France, personal data is protected by Act no. 78-87 of 6 January 1978, Act no. 2004-801 of 6 August 2004, article L. 226-13 of the French Penal Code and the European Directive of 24 October 1995.</p>
        <p>During use of the <a href="/">{url}</a>  website, the URL of links through which the User accessed the <a href="/">{url}</a>  website, the User’s internet provider and the User’s IP address can be collected.</p>
        <p>In any case, Saint Etienne Tourisme 16 avenue de la Libération BP 20031. 42001 Saint-Etienne Cedex 1 only collects personal information regarding the User required for the services proposed by the <a href="/">{url}</a>  website. The User provides this information with full knowledge of the facts, especially when they have entered it themselves. The User of the <a href="/">{url}</a>  website is explained whether or not they are obliged to provide this information.</p>
        <p>Pursuant to Articles 38 et seq. of French Act No. 78-17 of 6 January 1978 relating to information technology, databases, and civil liberties, Users have the right to access, rectify or oppose the use of their personal data by submitting a signed request in writing, together with a signed copy of proof of identity, and including the address to which the reply should be sent.</p>
        <p>No personal information about the User of the <a href="/">{url}</a> website is published without the knowledge of the User, or exchanged, transferred, assigned or sold in any format or medium to any third party.. </p>
        <p>Our databases are protected by the provisions of the French Act of 1 July 1998 transposing Directive 96/9/EC of 11 March 1996 on the legal protection of databases.</p>

        <h3>8. Hypertext links and cookies</h3>

        <p>The <a href="/">{url}</a> website contains a number of hypertext links to other websites, set up with the permission of {owner}. However , {owner} is not in a position to check the content of these linked websites, and accordingly cannot be held liable in this regard.</p>
        <p>Browsing on the <a href="/">{url}</a>  website may result in the installation of cookies on the User’s computer. A cookie is a small file that does not allow Users to be identified but records information on usage behaviour when visiting the website. The data obtained in this way is intended to facilitate subsequent visits to the website, as well as to enable various measurements of visit frequency.</p>
        <p>Refusal to install a cookie may make it impossible to access certain services. The User can however configure their computer as follows, to refuse the installation of cookies:</p>
        <br />
        <p className="underlined">In Internet Explorer</p>
        <ol>
          <li>Tool tab (the cog icon at the top right)</li>
          <li>Internet Options</li>
          <li>Click on Privacy</li>
          <li>Choose Decline all cookies</li>
          <li>Click on OK</li>
        </ol>
        <p className="underlined">In Firefox :</p>
        <ol>
          <li>At the top of the browser window, click the Firefox button</li>
          <li>Settings tab</li>
          <li>Select the Privacy tab</li>
          <li>Configure the Retention Rules</li>
          <li>Use custom settings for history</li>
          <li>Deselect the box to disable cookies</li>
        </ol>
        <p className="underlined">In Safari :</p>
        <ol>
          <li>In the top right of the browser, click on the menu icon (the cog icon).</li>
          <li>Select Settings</li>
          <li>Click on Show Advanced Settings</li>
          <li>In the "Privacy" section, click on Content Settings</li>
          <li>You can block cookies in the "Cookies" section.</li>
        </ol>
        <p className="underlined">In Chrome :</p>
        <ol>
          <li>In the top right of the browser, click on the menu icon (the three horizontal lines).</li>
          <li>Select Settings</li>
          <li>Click on Show Advanced Settings</li>
          <li>In the "Privacy" section, click on  Preferences</li>
          <li>You can block cookies in the "Privacy" section.</li>
        </ol>

        <h3>9. Applicable law and jurisdiction</h3>

        {/* <p>Tout litige en relation avec l’utilisation du site <a href="/">{url}</a> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de {city}.</p> */}
        <p>Any dispute in connection with the use of the <a href="/">{url}</a> website is subject to French law. Only the French courts are deemed competent.</p>

        <h3>10. Main applicable legislation</h3>

        <p>Act no. 78-87 of 6 January 1978, amended by Act No. 2004-801 of 6 August 2004 relating to data, databases and freedoms.</p>
        <p>Act No. 2004-575 of 21 June 2004 on confidence in the digital economy.</p>
        <p>General Data Protection Regulation 2016/679 (GDPR) of the European Union of 14 April 2016.</p>

        <h3>11. TERMS USED</h3>

        <p>User: A person accessing the aforementioned website.</p>
        <p>Personal information: "information that allows, in any form whatsoever, directly or not, the identification of the physical persons to whom it applies to " (article 4 of the Actno. 78-17 of 6 January 1978).</p>
    </LegalsViewWrapper>
      
      
    
      
      }
    
    
    </>
    
  );
}

LegalsView.propTypes = {
  infos: PropTypes.object.isRequired,
}

export default LegalsView;
