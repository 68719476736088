import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from "react-helmet";
import { WhiteLabelContext } from "ToolboxUtils/web/context/context";
import LegalsView from 'ToolboxComponents/webapp/pages/legals-view/legals-view';
import Loader from 'ToolboxComponents/commons/loader/loader';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';

import { PAGE_LOAD_WAITING } from 'Params/globals';

const Legals = props => {
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!infos.isComplete) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  const infos = useApiChuchoteurs.get(`/white-labels/${whiteLabel.id}`, {
    params: {filter: 'legals'},
  });

  useIsContentLoaded(infos.isComplete);

  return (
    <>
      {infos.isComplete ?
        <LegalsView infos={infos.data} />
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
    </>
  );
}

export default Legals;
